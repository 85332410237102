import { createGlobalStyle } from "styled-components";

export const breakpoints = { tablet: "600px" };

export default createGlobalStyle`

    :root{
      --green-color: #9AD04E; /* rgb(154, 208, 78) */
      --yellow-color: #FFD23F; /* rgb(255, 210, 63) */
      --graceful-grey: #545454;
      --black-color: #F4F3EE;
      --baby-blue:#6fb0eb;
      --max-content-width: 1200px;
      --font-family: 'Poppins', sans-serif;

      



Baby/Sky Blue:  (possibly use some of this colour, too?)
    }
*{
    box-sizing: border-box;
    ;
}
    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        vertical-align: baseline;
        box-sizing: border-box;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }
    body {
    /* background: var(--background-transparent); */
    min-height:100%!important;
    display: flex;
    flex-direction:column;
    } 

    html, body, #root{
height: 100% !important;
width:100%;
margin: 0;
    }
    ol, ul {
        list-style: none;
    }
    blockquote, q {
        quotes: none;
    }
    blockquote:before, blockquote:after,
    q:before, q:after {
        content: '';
        content: none;
    }
    h1, h2, h3 {
        font-size: 28px;
    }
    `;
