import { useEffect, useState } from "react";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../FirebaseConfig";
import styled from "styled-components";

const OrderProducts = ({ item }) => {
  const [product, setProduct] = useState({});

  useEffect(() => {
    const getProduct = async (id) => {
      const productRef = doc(db, "Products", id);
      const productSnap = await getDoc(productRef);
      if (productSnap.exists()) {
        setProduct({ id: productSnap.id, ...productSnap.data() });
      }
    };
    getProduct(item.id);
  }, []);
  return (
    <Container>
      <H5>
        {item.quantity} x {product.name}
      </H5>
    </Container>
  );
};

const Container = styled.div`
  margin: 4px 5px;
`;
const H5 = styled.h5`
  font-size: 1.2rem;
`;
export default OrderProducts;
