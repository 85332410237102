import React from "react";



const SearchResult = () => {
    return (
        <div>Placeholder for search results</div>
      );
}
 
export default SearchResult;