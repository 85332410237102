import React from "react";
import styled from "styled-components";
import Luna from "../../src/assets/Luna.jpg";
import WRSFG from "../../src/assets/WRSFG.jpg";
import CFKW from "../../src/assets/CommunityFridgeKWLogo.png";
import CSDS from "../../src/assets/CSDS.png";
import UUC from "../../src/assets/UUC.jpg";
import Taffys from "../../src/assets/Taffys.png";

import Erica from "../../src/assets/Erica.jpeg";
import Vembu from "../../src/assets/Vembu.jpeg";
import Header from "./Header/Header";
import { Row, Col } from "react-bootstrap";
import {
  BsInstagram,
  BsFacebook,
  BsTwitter,
  BsYoutube,
  BsFillEnvelopeFill,
  BsGlobe,
} from "react-icons/bs";
import { FaTiktok } from "react-icons/fa";

const Partners = () => {
  return (
    // <Container image={bckgrndImg}>
    <div>
      <Wrapper>
        <center>
          <Name>Our Partners</Name>
        </center>
        <Row className="mt-5">
          <Col lg={4}>
            <ImageContainer>
              <Image src={WRSFG} alt="picture of Luna"></Image>
            </ImageContainer>
          </Col>
          <Col>
            <ContentContainer>
              {/* <center> */}
              <Name target="_blank" href="https://seeds.ca/schoolfoodgardens/">
                School Food Gardens
              </Name>
              {/* </center> */}
              <Para>
                Our work, which operates through Seeds of Diversity, is focused
                on creating opportunities for youth to connect with food
                systems, learn sustainable practices, and explore careers in
                food and agriculture. This includes creating and maintaining
                physical resources like gardens on school grounds, and
                supporting youth inquiry into the food and sustainability
                related topics that interest them. Our in-person programs have
                built 
                <a href="https://seeds.ca/schoolfoodgardens/find-a-garden/">
                  35 food gardens at schools across Waterloo Region
                </a>{" "}
                since 2017. Our online youth volunteer program has connected
                with youth across Ontario to support them in learning about food
                systems and communicating about food and sustainability with
                their peers.
              </Para>
              <Para>
                <strong>
                  When you place an order with us, 25% of your purchase will go
                  towards supporting the School Food Gardens Project that builds
                  vegetables gardens in 45+ schools across KW-region.
                </strong>
              </Para>
              <SocialContainer>
                <FacebookLink href="https://www.facebook.com/wrschoolfoodgardens/">
                  <BsFacebook size={35} />
                </FacebookLink>
                <InstaLink href="https://www.instagram.com/wrschoolfoodgardens/">
                  <BsInstagram size={35} />
                </InstaLink>
                <PintrestLink href="https://www.youtube.com/channel/UCxm4t8jrSxochu66-LrQdAA">
                  <BsYoutube size={35} />
                </PintrestLink>
                <TwitterLink href="https://twitter.com/wrschoolgardens">
                  <BsTwitter size={35} />
                </TwitterLink>
                <TwitterLink href="mailto:wrschoolfoodgardens@gmail.com">
                  <BsFillEnvelopeFill size={35} />
                </TwitterLink>
                <FacebookLink href="https://www.tiktok.com/@wrschoolfoodgardens">
                  <FaTiktok size={35} />
                </FacebookLink>
              </SocialContainer>
            </ContentContainer>
          </Col>
        </Row>
      </Wrapper>
      <Wrapper>
        <Row>
          <Col lg={4}>
            <ImageContainer style={{ flexDirection: "column" }}>
              <Image src={CSDS} alt="CSDC Logo"></Image>
              <Image src={UUC} alt="UUC Logo"></Image>
            </ImageContainer>
          </Col>
          <Col>
            <ContentContainer>
              <Name target="_blank" href="http://www.centrestagedance.ca/">
                Centre Stage Dance Studio
              </Name>
              <br></br>

              <Name target="_blank" href="https://unlockunleashcontrol.com/">
                Unlock Unleash Control
              </Name>
              <Para>
                Centre Stage Dance Studio, located in New Hamburg, was founded
                by Donna Bender in 1984 and opened its sister location, Unlock
                Unleash Control, in Kitchener in 2010. The studios offer a
                variety of dance classes from classical ballet to hip hop, from
                tiny tots to adult classes, from beginner to advanced students.
                Our teachers encourage and inspire students to excel to their
                full potential in a fun and positive atmosphere, celebrating the
                dancers’ accomplishments and continual progress. We have seen
                many generations of dancers graduate from our studios, and are
                always thrilled when they come back to visit, share or partner
                with us in their own endeavors!
              </Para>
              <Para>
                As a former UUC dancer (Luna, me!), I learned immensely under
                the wonderful teachers at UUC and truly blossomed in the person
                I am today with the guided support and growth provided by the
                UUC/CSDS community.
              </Para>
              <Para>
                <strong>
                  When you place an order with us, 25% of your purchase will go
                  towards supporting Unlock Unleash Control Dance Studio &
                  Centre Stage Dance Studio in creating new dance programs and
                  experiences for children in KW Region.
                </strong>
              </Para>

              <SocialContainer>
                <strong>CSDS</strong>
                <FacebookLink href="https://www.facebook.com/CentreStageDanceStudioCSDS/">
                  <BsFacebook size={35} />
                </FacebookLink>
                <InstaLink href="https://www.instagram.com/centrestage_dancestudio/">
                  <BsInstagram size={35} />
                </InstaLink>
              </SocialContainer>
              <SocialContainer>
                <strong>UUC</strong>
                <FacebookLink href="https://www.facebook.com/UnlockUnleashControl">
                  <BsFacebook size={35} />
                </FacebookLink>
              </SocialContainer>
            </ContentContainer>
          </Col>
        </Row>
      </Wrapper>
      <Wrapper>
        <Row>
          <Col lg={4}>
            <ImageContainer>
              <Image src={Taffys} alt="Taffy's legacy logo"></Image>
            </ImageContainer>
          </Col>
          <Col>
            <ContentContainer>
              <Name target="_blank" href="https://www.taffyslegacy.com/donate">
                Taffy's Legacy
              </Name>
              <Para>
                Taffy’s Legacy Canine Rescue & Rehabilitation is a 100%
                volunteer run rescue located in Southern Ontario. We do not have
                a physical shelter, and all of our dogs available for adoption
                are in foster homes in Southern Ontario! Most of our foster
                homes are located in and around Kitchener-Waterloo, although we
                also have a great relationship with a kennel/training facility,
                the Dog’s Paw, in Delhi Ontario which helps out when needed.
              </Para>
              <Para>
                At TLC, we are passionate about helping rescue dogs find their
                perfect humans.  Our goal is to 'upcycle' homeless dogs by
                giving them the tools they need to succeed in their furever
                home. We accomplish this by having amazing fosters who volunteer
                their home, time and love to giving these dogs a second chance.
                In foster care we get to know their personality and their needs
                so that we can find the ultimate pairing of
                two-to-four-legger(s). We prioritize training and structure in
                our foster homes to set our dogs up for success going into their
                furever homes!
              </Para>
              <Para>
                The majority of our dogs are rescued through the International
                Fund for Animal Welfare (IFAW) and other remote communities in
                Northern Ontario and Quebec. We pull dogs from shelters when we
                have experienced foster homes available as well, although we
                prioritize our Northern Dogs.
              </Para>
              <Para>
                We set the bar high here at TLC with only the most ethical
                adoption and vetting protocols and standards. All dogs will come
                fully vaccinated, microchipped, heartworm tested and
                spayed/neutered. We also have partners in the training community
                to support or adopters post-adoption to make sure they have all
                the resources they need to be the best furever home possible!
              </Para>
              <Para>
                All of our food and supplies are donated, and our adoption fees
                cover all of our “basic” vetting, however sometimes dogs come
                into care requiring extra medical attention. All of our
                fundraisers go towards covering extra or unexpected medical
                bills. In 2021, we treated Alaksa’s Heartworm and Lyme Disease,
                removed a cancerous tumor from RuPaul, did a TPLO surgery for
                Lima and extracted teeth from Northern Spy!
              </Para>
              <Para>
                <strong>
                  When you place an order with us, 25% of your purchase will go
                  towards supporting Taffy’s Legacy in developing a medical fund
                  to cover the care and expenses of new foster pups.
                </strong>
              </Para>
              <SocialContainer>
                <FacebookLink href="https://www.facebook.com/TLCRR/">
                  <BsFacebook size={35} />
                </FacebookLink>
                <InstaLink href="https://www.instagram.com/taffyslegacycaninerescue/">
                  <BsInstagram size={35} />
                </InstaLink>
              </SocialContainer>
            </ContentContainer>
          </Col>
        </Row>
      </Wrapper>
      <Wrapper>
        <Row>
          <Col lg={4}>
            <ImageContainer>
              <Image src={CFKW} alt="Community Fridge KW's legacy logo"></Image>
            </ImageContainer>
          </Col>
          <Col>
            <ContentContainer>
              <Name
                target="_blank"
                href="https://www.facebook.com/CommunityFridgeKW/"
              >
                Community Fridge KW
              </Name>
              <Para>
                Community Fridge KW is a{" "}
                <strong>grassroots community organization</strong> that seeks to
                establish and operate public repositories of fresh donated food
                for anyone to take from, for free in and around
                Kitchener-Waterloo. Open 24/7 and void of data collection or
                monitoring, the fridge strives to offer the community by
                reaching three primary goals that follow:
              </Para>
              <Para>
                1. Reduce food insecurity by offering a barrier-free source of
                fresh free food.
              </Para>
              <Para>
                2. Reduce food waste by connecting local businesses maintaining
                inspected kitchens to the fridge as an opportunity to share food
                that might otherwise be wasted.
              </Para>
              <Para>
                3. Facilitate organic opportunities for the development of
                mutual trust, understanding and respect.
              </Para>
              <Para>
                <strong>
                  When you place an order with us, 25% of your purchase will go
                  towards supporting Community Fridge KW’s mission to reduce
                  local food insecurity through their public food repository,
                  open 24/7.
                </strong>
              </Para>
              <SocialContainer>
                <FacebookLink href="https://www.facebook.com/CommunityFridgeKW/">
                  <BsFacebook size={35} />
                </FacebookLink>
                <InstaLink href="https://www.instagram.com/communityfridgekw/?hl=en">
                  <BsInstagram size={35} />
                </InstaLink>
              </SocialContainer>
            </ContentContainer>
          </Col>
        </Row>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  margin: 50px 2rem;
  background-color: white;
  border-radius: 12px;
`;

const Image = styled.img`
  width: 100%;
  object-fit: cover;
  opacity: 1;
  margin: auto;
  border-radius: 10px;
  // border: solid 1px var(--yellow-color);
  vertical-align: middle;
`;
const Para = styled.p`
  font-size: 1rem;
  color: black;
  margin: 1rem 0;
  font-weight: regular;
`;
const Name = styled.a`
  color: black;
  text-decoration: none;
  font-size: 38px;
  font-weight: bolder;
  @media (max-width: 992px) {
    text-align: center;
  }
  &:hover {
    color: green;
    cursor: pointer;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  text-align: center;
  vertical-align: middle;
  height: 100%;
  margin: auto;
`;
const ContentContainer = styled.div``;

const FacebookLink = styled.a`
  margin: 10px;
  color: #3b5998;
  &:hover {
    color: green;
  }
`;

const InstaLink = styled.a`
  margin: 10px;
  color: #8a3ab9;
  &:hover {
    color: green;
  }
`;

const TwitterLink = styled.a`
  margin: 10px;
  color: #00acee;
  &:hover {
    color: green;
  }
`;

const PintrestLink = styled.a`
  margin: 10px;
  color: red;
  &:hover {
    color: green;
  }
`;

const SocialContainer = styled.div`
  margin-top: 2rem;
  @media (max-width: 992px) {
    display: table;
    margin: 2rem auto;
  }
`;

export default Partners;
