import React, { useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
//icons
// import { BsSearch } from "react-icons/bs";
// import { RiCloseFill } from "react-icons/ri";
import { GiShoppingCart } from "react-icons/gi";
//firebase
// import { db } from "../FirebaseConfig";
// import { collection, getDocs } from "firebase/firestore";

const Search = () => {
  const navigate = useNavigate();
  const [isSearchClicked, setIsSearchClicked] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  // const handleSearchChange = (e) => {
  //   setSearchTerm(e.target.value);
  // };

  // const handleKeyDown = (eve) => {
  //   if (eve.key === "Enter") {
  //     navigate(`/searchResult/${searchTerm}`);
  //   }
  // };

  // const handleSubmit = () => {
  //   navigate(`/searchResult/${searchTerm}`);
  // };

  // const handleClearClick = () => {
  //   setSearchTerm("");
  // };
  // const handleSearchClick = (e) => {
  //   e.stopPropagation();
  //   setIsSearchClicked(!isSearchClicked);
  // };

  return (
    <SearchWrapper>
      <ButtonCart>
        <GiShoppingCart onClick={() => navigate("/OrderForm")} />
      </ButtonCart>
      {/* <ButtonSearch onClick={handleSubmit}>
        <BsSearch onClick={(e) => handleSearchClick(e)} />
      </ButtonSearch>
      {isSearchClicked && (
        <Input
          type="text"
          value={searchTerm}
          onChange={handleSearchChange}
          onKeyDown={(ev) => handleKeyDown(ev)}
          placeholder="start typing to search..."
          aria-label="search for entries matching your term"
        ></Input>
      )}
      {isSearchClicked && (
        <ButtonClear onClick={handleClearClick}>
          <RiCloseFill />
        </ButtonClear>
      )} */}
    </SearchWrapper>
  );
};

const ButtonSearch = styled.button`
  height: 2rem;
  width: 40px;
  background: none;
  border: 1px solid white;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  color: #616060;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: var(--font-family);
  border-right: none;
  display: flex;
  margin: 15px 0;
  justify-content: center;
  align-items: center;
  border-radius: 10px 0px 0px 10px;
  // padding: 1px 2px;
  &:hover {
    color: var(--green-color);
  }
`;

const ButtonCart = styled.button`
  height: 2.5rem;
  width: 40px;
  background: none;
  border: 1px solid white;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  color: #616060;
  font-size: 2.5rem;
  font-weight: 700;
  font-family: var(--font-family);
  border-right: none;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px 0px 0px 10px;
  &:hover {
    color: var(--green-color);
  }
  -webkit-appearance: none;
`;

const ButtonClear = styled.button`
  height: 60px;
  width: 40px;
  background: none;
  border: 1px solid white;
  border-radius: 0px 10px 10px 0px;
  cursor: pointer;
  position: relative;
  text-decoration: none;
  color: #616060;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: var(--font-family);
  /* border-right: none; */
  /* background: rgba(188, 184, 177, 0.3); */
  border-left: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 15px 0;
  /* padding: 1px 2px; */
  &:hover {
    color: var(--green-color);
  }
`;

const Input = styled.input`
  height: 60px;
  width: 150px;
  font-size: 1rem;
  color: #616060;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family);
  /* background: rgba(188, 184, 177, 0.3); */
  border: solid 1px white;
  outline: none;
  border-left: none;
  border-right: none;
  padding: 1px 2px;
  margin: 15px 0;
`;

const SearchWrapper = styled.div`
  display: flex;
  justify-content: center;
  background: none;
  border: none;
  font-family: var(--font-family);
  @media (max-width: 917px) {
    position: relative;
    right: 1rem;
    bottom: 0.5rem;
  }
  -webkit-appearance: none;
`;

export default Search;
