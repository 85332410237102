import React from "react";
import styled from "styled-components";
import Receipts from "./Receipts";
import bckgrndImg from "../Components/saplings.jpg";
import Luna from "../../src/assets/Luna.jpg";
import Erica from "../../src/assets/Erica.jpeg";
import Vembu from "../../src/assets/Vembu.jpeg";
import Header from "./Header/Header";
import Madhu from "../../src/assets/Madhu.jpg"
import { Row, Col } from "react-bootstrap";

const AboutUs = () => {
  return (
    <div>
      <center>
        <Donation>🎉 $1050 Donated to Date! 🎉 </Donation>
        <Title>What We Support</Title>
      </center>
      <Receipts />
      <center>
        <Title>About Us</Title>
      </center>
      <Wrapper>
        <Row>
          <Col lg={4}>
            <ImageContainer>
              <Image src={Luna} alt="picture of Luna"></Image>
            </ImageContainer>
          </Col>
          <Col>
            <ContentContainer>
              <Name>Luna</Name>
              <Para>
                Nice to meet you! My name is Luna, and I'm the creator of
                Midnight Nursery. As a second-year university student and plant
                enthusiast with a passion for business, I'm thrilled to
                introduce you to my first summer venture.
              </Para>
              <Para>
                I settled on the name{" "}
                <span style={{ fontWeight: "bold" }}>
                  Midnight Nursery, as all my brightest and most daring ideas
                  come to me in the late hours past midnight{" "}
                </span>{" "}
                — this one included.
              </Para>
              <Para>
                This summer project started early March 2020, when I decided to
                sprout my own seedlings for the garden. With a packet of
                homegrown seeds, some soil and a few egg cartons; I planted,
                watered, and waited (and waited and waited and waited) for
                sprouts thinking that only a few of many seeds would grow.
              </Para>
              <Para>
                A week later, to my surprise, my trays filled with many more
                sprouts that I ever imagined. My friends told me to thin them
                out, but I found the seedlings to be bright and vibrant little
                plants with immense potential to produce veggies and a little
                joy along the way. Many bags of soil, watering sessions, and
                hours of research later — I'm happy to bring you strong, healthy
                seedlings that have been hardened off for Canadian weather.
              </Para>
              <Para>
                Although my original goal this summer was to use my profits to
                save up for something nice, I can't justify my purchases when
                there are so many causes that need support — now more than ever.
                With this in mind, we have commited to a minimum of 25% of all
                current and future profits towards charity.
              </Para>
              <Para>
                <br />
                <strong>
                  Place an order with us to start your own growing adventure!
                </strong>
              </Para>
            </ContentContainer>
          </Col>
        </Row>
      </Wrapper>
      <Wrapper>
        <Row>
          <Col lg={4}>
            <ImageContainer>
              <Image src={Vembu} alt="picture of Vembu"></Image>
            </ImageContainer>
          </Col>
          <Col>
            <ContentContainer>
              
                <Name>Vembu</Name>
                <Para>
                Hi! My name is Vembu, and I'm very excited to be a part of the Midnight Nursery team. I'm a third-year Management Engineering student at the University of Waterloo. I have a passion for plants, technology, and business, and I enjoy bringing that to the Nursery.
                </Para>
                <Para>
                I joined Midnight Nursery in 2021, but I've know Luna for 6 years now! Having worked on countless projects with Luna throughout high school, I have a lot of fond memories of long video calls, perfect slide decks, and a lot of laughter. I've watched Midnight Nursery grow over the past 3 years, and I can attest to its dedication to quality and giving back to the community. Getting the opportunity to work with Luna and Madhu to create the Midnight Nursery
                </Para>
              
            </ContentContainer>
          </Col>
        </Row>
      </Wrapper>
      <Wrapper>
      <Row>
          <Col lg={4}>
        <ImageContainer>
          <Image src={Erica} alt="picture of Erica"></Image>
        </ImageContainer>
        </Col>
        <Col>
        <ContentContainer>
          <Name>Erica</Name>
          <Para>
            My name's Erica and I'm honoured to be the co-founder and creative
            lead for Midnight Nursery!
          </Para>
          <Para>
            I'm heading into my first year of university in the fall and I can't
            wait to start a new chapter of my life.
          </Para>
          <Para>
            Luna has always been a dear friend to me. So upon learning about her
            aspirations for Midnight Nursery, I found her excitement and passion
            to be inspiring. Not only is Midnight Nursery special for its
            dedication to quality, but the money we raise from sales will be
            given back to our community. I hope to share our excitement and
            passion with all of you!
          </Para>
          <Para>
            Due to increased responsibilities going into my second year of
            university studies, I made the decision to step back from my role as
            media coordinator as of the summer of 2021. In my two years working
            with Luna on Midnight Nursery, I have learned so much and have been
            so inspired by the tenacity, determination, and creativity she
            demonstrated. The work that Midnight Nursery is doing is so
            incredibly important and will always have a special place in my
            heart. I’m so unbelievably grateful to Luna for having been part of
            such an amazing business, and I’ll forever cherish my time working
            on Midnight Nursery! Thank you, Luna, for an amazing two years
            together and thank you, everyone, for supporting our work!
          </Para>
        </ContentContainer>
        </Col>
        </Row>
        </Wrapper>
        <Wrapper>
        <Row>
          <Col lg={4}>
        <ImageContainer>
          <Image src={Madhu} alt="picture of Madhu"></Image>
        </ImageContainer>
        </Col>
       <Col>
        <ContentContainer>
          <Name>Madhu</Name>
          <Para>
            My name is Madhu and I am a recent Bootcamp graduate from Concordia university in full stack web development.I also have about a decade of experience in the Financial industry in a variety of
            roles ranging from customer service to business analysis. I am currently pursuing a part-time MBA at Wilfrid Laurier University.
          </Para>
          <Para>
          I am happy to have this opportunity to work with Luna and Vembu on this 
            website.I have known Luna since her high school days through Vembu and I have a lot of respect for her.
            She has always amazed me with her multi-talent and her passion towards giving back to society.
            Luna was always very clear on the requirements for functionalities and appearance of the website which made it very easy for us to develop this site.I had a lot of fun building this website with Vembu.I wish Midnight
            Nursery huge success!
          </Para>
        </ContentContainer>
        </Col>
        </Row>
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.div`
  margin: 50px 2rem;
  background-color: white;
  border-radius: 12px;
`;

const Title = styled.h1`
  font-size: 3rem;
  margin: 25px 0;
`;

const Donation = styled.h1`
  font-size: 1.5rem;
  height: 5vh;
  padding: 25px 0;
  background-color: var(--green-color);
  color: white;
  @media (max-width: 550px) {
    font-size: 1.5rem;
    padding-bottom:50px;
  }
  @media (min-width: 760px){
    padding-bottom:70px;
    font-size: 2rem;

  }
  @media (max-width: 290px) {
    font-size: 1rem;
    padding-bottom:40px;
  }
`;

const Image = styled.img`
  height: 50vh;
  opacity: 1;
  margin: auto;
  border-radius: 10px;
  border: solid 1px var(--yellow-color);
  @media (max-width: 990px) {
    width: 50%;
    height: auto;
    margin: auto;
  }
  @media (max-width: 475px) {
    width: 100%;
    height: auto;
  }
`;
const Para = styled.p`
  font-size: 1rem;
  /* margin: 10px; */
  margin-bottom: 5px;
  color: black;
  font-weight: regular;
  
`;
const Name = styled.div`
  color: black;
  font-size: 38px;
  font-weight: bolder;
  @media (max-width: 992px) {
    text-align: center;
  }
`;
const ImageContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;

`;
const ContentContainer = styled.div`
display: flex;
flex-direction:column;
`;

export default AboutUs;
