import Lettuce from "./Main/Lettuce";
import Weather from "./Weather";
import Shop from "./Shop";

const LandingPage = () => {
  return (
    <div>
      <Lettuce />
      <Weather />
      <Shop />
    </div>
  );
};

export default LandingPage;
