import './App.css';

import LandingPage from "./Components/LandingPage";
import GlobalStyles from "./Components/GlobalStyles";
import AboutUs from "./Components/AboutUs";
import OrderInfo from "./Components/OrderInfo";
import GrowingResources from "./Components/GrowingResources";
import Order from "./Components/Order";
import Shop from "./Components/Shop";
import OrderLookup from "./Components/OrderLookup";
import Partners from "./Components/Partners";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Footer from "./Components/footer/Footer";
import Header from "./Components/Header/Header";
import ErrorPage from "./Components/Main/ErrorPage";
// import headerImage from "../src/Components/veggieGarden.jpg";
// import { Navbar, Nav, Container } from "react-bootstrap";
// import Search from "./Components/Search";
import SearchResults from "./Components/SearchResults";
import Loading from "./Components/Main/Loading";
import Item from "./Components/Item";

const App = () => (
  <BrowserRouter>
    <GlobalStyles />
    <div>
      <Header/>
      <Routes>
        <Route exact path="/" element={<LandingPage />} />
        <Route exact path="/AboutUs" element={<AboutUs />} />
        <Route exact path="/Partners" element={<Partners />} />
        <Route exact path="/Growing" element={<GrowingResources />} />
        <Route exact path="/OrderForm" element={<Order />} />
        <Route exact path="/OrderInfo" element={<OrderLookup />} />
        <Route exact path="/Shop" element={<Shop isHome={true} />} />
        <Route exact path="/Search/:product" element={<SearchResults />} />
        <Route exact path="/OrderInfo/:orderId" element={<OrderInfo />} />
        {/* <Route
          exact
          path="/growingResources/:name"
          element={<GrowingResDetails />}
        /> */}
      </Routes>
      <Footer />
    </div>
  </BrowserRouter>
);

export default App;
