import { Row, Card, Col, Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { db } from "../FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import Carousel from "react-elastic-carousel";
import "../App.css";

const Receipts = () => {
  const [receipts, setReceipts] = useState([]);
  const [index, setIndex] = useState(0);
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 },
  ];
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  useEffect(() => {
    getDocs(collection(db, "Receipts")).then((doc) => {
      const receiptsList = [];
      doc.forEach((receipt) => {
        receiptsList.push({
          id: receipt.id,
          donationDate: new Date(receipt.data().date["seconds"] * 1000),
          ...receipt.data(),
        });
      });
      setReceipts(receiptsList);
    });
  }, []);

  return (
    <Container>
      <Row xs={1} md={2} className="g-4">
        <Carousel breakPoints={breakPoints}>
          {receipts.map((receipt) => (
            <Col>
              <Card style={{ height: "100%" }}>
                <a
                  target="_blank"
                  href={receipt.image}
                  title="click here to see the receipt"
                >
                  <Card.Img
                    variant="top"
                    src={receipt.image}
                    style={{
                      height: "40vh",
                      objectFit: "cover",
                      borderRadius: "10px",
                    }}
                  />
                </a>
                <Card.Body className="receipts-body">
                  <Card.Title>{receipt.charity}</Card.Title>
                  <Card.Text>
                    {months[receipt.donationDate.getMonth()]}{" "}
                    {receipt.donationDate.getYear() - 69 - 31} -{" "}
                    <span
                      style={{
                        fontWeight: "bold",
                        textDecoration: "underline",
                      }}
                    >
                      ${receipt.amount}
                    </span>{" "}
                    donation made to {receipt.charity}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Carousel>
      </Row>
    </Container>
  );
};

export default Receipts;
