import { useState } from "react";
import { useNavigate } from "react-router";
import cuteImage from "./comingSoonBlue.png";
import { Row, Col, Container } from "react-bootstrap";
import styled from "styled-components";

const OrderLookup = () => {
  const navigate = useNavigate();
  const [id, setId] = useState();

  return (
    <Container>
      <Row>
        {/* <Col xs={12} className="d-flex justify-content-center mt-4 mb-4">
          <h5>
            Please keep an eye out for an email from us (titled "Your order is
            ready") in late April/early May. Please follow our socials
            (Facebook: @midnightnurserykw, Instagram: @midnightnursery) or email
            us at midnightnurserykw@gmail.com for questions and inquiries.
          </h5>
        </Col> */}
        <Col xs={12} className="d-flex justify-content-center mb-4 mt-4">
          <p>Look up your order below:</p>
        </Col>
        <Col xs={12} className="d-flex justify-content-center mb-2">
          <input
            type="text"
            placeholder="Enter Confirmation Number"
            style={{ width: "75vh" }}
            value={id}
            onChange={(e) => setId(e.target.value)}
            name="id"
          />
          <CardButton
            onClick={() => navigate(`/orderInfo/${id}`, { replace: true })}
          >
            Search!
          </CardButton>
        </Col>
        {/* <Row>
          <Col xs={12} className="d-flex justify-content-center">
            <img src={cuteImage} style={{ width: "20rem" }}></img>
          </Col>
        </Row> */}
        <Col className="d-flex justify-content-center mt-4 mb-4 pb-4">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSckrUc_kT8Jiw0mjuVYOWqmlnZjudQurO905E3MWTNV-_s3oQ/viewform?embedded=true"
            width="650"
            height="1300"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          >
            Loading…
          </iframe>
        </Col>
      </Row>
    </Container>
  );
};

const CardButton = styled.button`
  // width: 50%;
  height: auto;
  border-radius: 2px;
  font-weight: bold;
  background-color: rgb(34, 139, 34, 1);
  border: 1px solid black;
  color: white;

  font-size: 1rem;
  padding: 5px;
  &:hover {
    background-color: white;
    border: 1px solid green;
    color: green;
  }
`;

export default OrderLookup;
