import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Search from "../Search";
import Hamburger from "./Hamburger";
import logo from "../../assets/Logo.PNG";
import React, { useState } from "react";
import Order from "../Order";

const Navbar = () => {
  const [openBurger, setOpenBurger] = useState(false);

  const toggleBurger = () => {
    const temp = openBurger;
    setOpenBurger(!temp);
  };
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <Wrapper>
        <LogoContainer>
          <Logo alt="midnight nursery logo" src={logo} />
          <Header1>Midnight Nursery</Header1>
        </LogoContainer>

        <NavMenu>
          <NavigationMenu to="/">Home</NavigationMenu>
          <NavigationMenu to="/AboutUs">About Us</NavigationMenu>
          <NavigationMenu to="/Partners">2022 Partners</NavigationMenu>
          <NavigationMenu to="/Growing">Growing Resources</NavigationMenu>
          <NavigationMenu to="/OrderInfo">Pickup/Delivery</NavigationMenu>
          <NavigationMenu to="/Shop">Shop</NavigationMenu>
        </NavMenu>
        <MenuOptions>
          <Searchbar>
            <Search />
          </Searchbar>
          <Hamburger
            onClick={toggleBurger}
            openBurger={openBurger}
            toggleBurger={toggleBurger}
          />
        </MenuOptions>
      </Wrapper>
      {openBurger ? (
        <BurgerMenu>
          <NavigationMenu onClick={() => setOpenBurger(false)} to="/">
            Home
          </NavigationMenu>
          <NavigationMenu onClick={() => setOpenBurger(false)} to="/AboutUs">
            About Us
          </NavigationMenu>
          <NavigationMenu onClick={() => setOpenBurger(false)} to="/Partners">
            2022 Partners
          </NavigationMenu>
          <NavigationMenu onClick={() => setOpenBurger(false)} to="/Growing">
            Growing Resources
          </NavigationMenu>
          <NavigationMenu onClick={() => setOpenBurger(false)} to="/OrderInfo">
            Pickup/Delivery
          </NavigationMenu>
          <NavigationMenu onClick={() => setOpenBurger(false)} to="/Shop">
            Shop
          </NavigationMenu>
          <NavigationMenu onClick={() => setOpenBurger(false)} to="/OrderForm">
            Cart
          </NavigationMenu>
        </BurgerMenu>
      ) : null}
    </div>
  );
};

const Wrapper = styled.div`
  display: flex;
  width: 100vw;
  height: 90px;
  align-items: center;
  @media (max-width: 917px) {
    justify-content: space-between;
  }
`;

const MenuOptions = styled.div`
  @media (max-width: 917px) {
    display: flex;
    justify-content: flex-end;
  }
`;

const BurgerMenu = styled.div`
  color: #616060;
  text-decoration: none;
  display: none;
  font-size: 1rem;
  font-weight: 400;
  justify-content: space-between;
  align-items: center;
  &:hover {
    color: green !important;
  }
  @media (max-width: 1200px) {
    font-size: 0.7rem;
  }
  @media (max-width: 917px) {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    text-align: center;
  }
`;
const Logo = styled.img`
  max-width: 250px;
  height: 250px;
  position: relative;
  top: -80px;
  @media (max-width: 917px) {
    width: 75px;
    height: 75px;
  }
`;
const LogoContainer = styled.section`
  position: relative;
  top: 50px;
  width: 250px;
  @media (max-width: 917px) {
    width: 75px;
    top: 50px;
    width: 150px;
  }
`;
const Header1 = styled.p`
  position: relative;
  top: -300px;
  left: 150px;
  font-size: 4rem;
  color: white;
  white-space: nowrap;
  font-weight: 500;
  @media (max-width: 917px) {
    font-size: 1.5rem;
    top: -150px;
    left: 60px;
    z-index: 100;
  }
`;
const NavigationMenu = styled(NavLink)`
  color: #616060;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 400;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  &:hover {
    color: green !important;
  }
  @media (max-width: 1200px) {
    font-size: 0.7rem;
  }
  @media (max-width: 977px) {
    padding: 10px 5px;
  }
  @media (max-width: 917px) {
    width: 100% !important;
    text-align: center;
    font-size: 0.9rem;
    background-color: green;
    color: white;
    &:hover {
      color: black;
      background-color: white;
    }
  }
`;
const NavMenu = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 60%;
  @media (max-width: 977px) {
    width: 80%;
  }
  @media (max-width: 917px) {
    display: none;
  }
  @media (max-width: 675px) {
    display: none;
  }
`;
const Searchbar = styled.div`
  // display: relative;
  justify-content: space-around;
  margin-left: 60px;
`;
export default Navbar;
