import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { growingRes } from "../data/growingResources";
import cuteImage from "./comingSoon.png";

const GrowingResources = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(`/growingResources/${growingRes.name}`);
  };

  return (
    <Wrapper>
      {growingRes.map((res) => {
        <ul key={growingRes.id}>
          <List onClick={handleClick}>
            <Image src={growingRes.imgSrc} />
            <Para>{growingRes.name}</Para>
          </List>
        </ul>;
      })}
      <center>
        <h1>Coming soon! We're still growing this page. Check back soon!</h1>
        <img src={cuteImage} style={{ height: "50vh" }}></img>
      </center>
    </Wrapper>
  );
};

const Wrapper = styled.div``;
const List = styled.li`
  list-style: none;
`;
const Para = styled.p`
  font-size: 1rem;
`;
const Image = styled.img``;
export default GrowingResources;
