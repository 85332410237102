import React from "react";
import Topbar from "./Topbar";
import Navbar from "./Navbar";
import logo from "../../../src/logo.png";
import styled from "styled-components";

const Header = () => {
  return (
    <Wrapper>
      <Topbar />
      <Navbar />
    </Wrapper>
  );
};

const Logo = styled.img`
  max-width: 160px;
  height: 160px;
  z-index: 500;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: solid 2px var(--green-color);
`;
export default Header;
