import { useEffect, useState } from "react";
import Header from "./Header/Header";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { db } from "../FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import CheckoutForm from "./Main/CheckoutForm";
import { generatePrimeSync } from "crypto";

const Order = () => {
  const [products, setProducts] = useState([]);
  const [total, setTotal] = useState(0);

  const getItemPrice = (quantity, orderQuantity, price) => {
    if (orderQuantity <= quantity) {
      let amount = Number(orderQuantity * Number(price));
      return amount.toFixed(0);
    } else {
      return false;
    }
  };

  useEffect(async () => {
    let totalAmount = 0;
    products.forEach((product) => {
      const itemAmount = getItemPrice(
        product.quantity,
        product.orderQuantity,
        product.price
      );
      if (itemAmount) totalAmount += Number(itemAmount);
    });
    setTotal(totalAmount.toFixed(2));
  }, [products]);

  useEffect(async () => {
    let productList = [];
    if (localStorage.getItem("cart") != "") {
      JSON.parse(localStorage.getItem("cart"))?.forEach(async (product) => {
        const docRef = doc(db, "Products", product.productId);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const result = {
            id: docSnap.id,
            name: docSnap.data().name,
            image: docSnap.data().image,
            quantity: docSnap.data().quantity,
            orderQuantity: product.quantity,
            price: docSnap.data().price,
          };
          setProducts([...productList, result]);
          productList = [...productList, result];
        }
      });
    }
  }, []);
  return (
    <div>
      <Container fluid>
        {/* <ShopTitle>YoPreorder</ShopTitle> */}
        <ShopInfo>
          We accept cash/e-transfer at delivery, we want to ensure you are happy
          with your order and plants before payment! If online-checkout is a
          feature you believe would benefit your shopping experience - shoot us
          an email, we are always looking to improve our site!
        </ShopInfo>
        <CartTitle>Your Cart (Preorder)</CartTitle>
        {products.length ? (
          <Row>
            <Col xs={12} xl={8}>
              <Row className="mb-3" style={{ backgroundColor: "lightGray" }}>
                <Col md={3} xs={0} className="d-none d-lg-block"></Col>
                <Col xs={3} className="d-none d-lg-block">
                  Product
                </Col>
                <Col md={2} xs={0} className="d-none d-lg-block">
                  Price
                </Col>
                <Col md={2} className="d-none d-lg-block">
                  Quantity
                </Col>
                <Col className="d-none d-lg-block" md={2}>
                  Total
                </Col>
              </Row>
              {products.map((product) => (
                <Row className="centered border">
                  <Col md={3} xs={12}>
                    <img
                      style={{
                        width: "100%",
                        height: "13vh",
                        padding: "5px",
                        objectFit: "cover",
                      }}
                      src={product.image}
                    />
                  </Col>
                  <Col md={3} xs={12}>
                    <div>
                      <strong>{product.name}</strong>
                    </div>
                  </Col>
                  <Col md={2} xs={12}>
                    <span className="hide">Price: </span>${product.price}
                  </Col>
                  <Col md={2} xs={12}>
                    <span className="hide">x</span>
                    {product.orderQuantity}
                  </Col>
                  <Col xs={12} md={2}>
                    <span className="hide">Total: </span>
                    {getItemPrice(
                      product.quantity,
                      product.orderQuantity,
                      product.price
                    )
                      ? `
                        $${getItemPrice(
                          product.quantity,
                          product.orderQuantity,
                          product.price
                        )}`
                      : "Out of Stock"}
                  </Col>
                </Row>
              ))}
              <hr />
              <Row className="mb-3">
                <Col lg={10} xs={6} className="right-align">
                  Cart Total:
                </Col>
                <Col lg={2} xs={6}>
                  $ {total}
                </Col>
              </Row>
            </Col>
            <Col xs={12} xl={4}>
              <Row
                className="center border mx-1"
                // style={{ height: "100%", width: "100%" }}
              >
                <CheckoutForm total={total} products={products} />
              </Row>
            </Col>
          </Row>
        ) : (
          <div>Looks like your cart is empty!</div>
        )}
      </Container>
    </div>
  );
};

const ShopTitle = styled.h1`
  font-size: 3rem;
  // margin-left: 3rem;
  // margin-top: 3rem;
`;

const CartTitle = styled.h1`
  font-size: 2rem;
  margin-bottom: 1rem;
`;

const Container = styled.div`
  margin: 5px 5vw;
`;

const ShopInfo = styled.div`
  margin: 30px 0;
`;

export default Order;
