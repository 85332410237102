import React from "react";
import { RiPlantLine } from "react-icons/ri";

import styled, {keyframes} from "styled-components";

const Loading = () => {
    return (
        <Wrapper>
        <Spinner>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </Spinner>
      </Wrapper> )
}
 
export default Loading;

const AnimSpinner1 = keyframes`
0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }

`;

const AnimSpinner2 = keyframes`
0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }

`;

const AnimSpinner3 = keyframes`
0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }

`;

const Wrapper = styled.div`
width:100%;
height:100%;
display: flex;
justify-content:center;
align-items:center;
`


const Spinner = styled.div`
  display: inline-block;
  position: relative;
  width: 100px;
  height: 100px;
  margin:auto;
  
  text-align:center;

  & div{
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background:rgb(154, 208, 78,0.8);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  & div:nth-child(1) {
    left: 8px;
    animation: ${AnimSpinner1} 0.6s infinite;
  }

  & div:nth-child(2) {
    left: 8px;
    animation: ${AnimSpinner2} 0.6s infinite;
  }

  & div:nth-child(3) {
    left: 32px;
    animation: ${AnimSpinner2} 0.6s infinite;
  }

  & div:nth-child(4) {
    left: 56px;
    animation: ${AnimSpinner3} 0.6s infinite;
  }
`;
