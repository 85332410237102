import React from "react";
import styled from "styled-components";
import lettuce from "../../Components/lettuce-background.jpg";
import { useNavigate } from "react-router";
import { Row, Col, Container } from "react-bootstrap";

const Lettuce = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/shop");
  };
  return (
    <div>
      <Background style={{ backgroundImage: `url(${lettuce})` }}>
        <Container>
          <Row>
            <Col xs={12} className="center">
              <Span>Summer 2022 Vegetables</Span>
            </Col>
            <Col className="center">
              <Button onClick={handleNavigate}>Shop now</Button>
            </Col>
          </Row>
        </Container>
      </Background>
    </div>
  );
};

const Background = styled.div`
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
`;

const Span = styled.p`
  font-family: var(--font-family);
  font-size: 6rem;
  color: white;
  text-align: center;
  font-weight: 700;
  line-height: 8rem;
  margin: 5vh 0;
  @media (max-width: 670px) {
    line-height: 6rem;
    font-size: 4rem;
  }
  @media (max-width: 410px) {
    line-height: 5rem;
    font-size: 3rem;
  }
  @media (max-width: 330px) {
    line-height: 4 rem;
    font-size: 2rem;
  }
`;

const ContentContainer = styled.div`
  // position: relative;
  top: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
`;
const Button = styled.button`
  width: 30vw;
  height: 15vh;
  border-radius: 15px;
  border: none;
  font-size: 2.5rem;
  font-weight: bolder;
  font-style: italic;
  margin-bottom: 25px;
  &:hover {
    // background-color: var(--green-color);
    color: green;
    border: 1px solid var(--green-color);
  }
  @media (max-width: 726px) {
    font-size: 1.5rem;
    width: 30vw;
    height: 10vh;
  }
  @media (max-width: 410px) {
    font-size: 1rem;
    width: 30vw;
    height: 10vh;
  }
  @media (max-width: 330px) {
    font-size: 0.8rem;
    width: 25vw;
    height: 8vh;
  }
`;
export default Lettuce;
