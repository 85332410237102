import React from "react";
import styled from "styled-components";
import saplings from "../bluesky.png";

const Topbar = () => {
  return (
    <div>
      <Image alt="image of sky" src={saplings} />
    </div>
  );
};

const Image = styled.div`
  width: 100%;
  height: 160px;
  background-image: linear-gradient(
      to left,
      rgba(0, 0, 0, 0),
      70%,
      rgba(0, 0, 0, 0.4)
    ),
    url(${saplings});
  background-size: cover;
  @media (max-width: 917px) {
    height: 50px;
  }
`;
export default Topbar;
