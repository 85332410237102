import ItemPreview from "./ItemPreview";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Col, Row, Modal } from "react-bootstrap";
import styled from "styled-components";
import { db } from "../FirebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import Carousel from "react-elastic-carousel";

const Shop = ({ isHome }) => {
  const [products, setProducts] = useState([]);
  const [cart, setCart] = useState({});
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [showItem, setShowItem] = useState(false);
  const [showProduct, setShowProduct] = useState();

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2, pagination: false },
    { width: 850, itemsToShow: 3 },
    { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
    { width: 1450, itemsToShow: 5 },
    { width: 1750, itemsToShow: 6 },
  ];

  const localStorageToCart = () => {
    let productList = [];
    if (localStorage.getItem("cart") !== "") {
      productList = JSON.parse(localStorage.getItem("cart"));
    }
    let items = {};
    if (productList) {
      productList.forEach((item) => {
        items[item.productId] = item.quantity;
      });
    }
    setCart(items);
  };

  useEffect(() => {
    localStorageToCart();
  }, []);

  const addtoCart = (id, quantity) => {
    let productList = [];
    let newList = [];
    if (localStorage.getItem("cart") !== "") {
      productList = JSON.parse(localStorage.getItem("cart"));
    }
    if (quantity > 0) {
      if (productList) {
        newList = productList.filter((product) => {
          return product.productId !== id;
        });
      }
      newList.push({
        productId: id,
        quantity: quantity,
      });
    }
    localStorage.setItem("cart", JSON.stringify(newList));
    localStorageToCart();
  };

  const onHide = () => {
    setShowItem(false);
  };

  useEffect(() => {
    getDocs(collection(db, "Products")).then((doc) => {
      const productList = [];
      doc.forEach((prod) => {
        productList.push({ id: prod.id, ...prod.data() });
      });
      setProducts(productList);
    });
  }, []);
  return (
    <div>
      <Modal
        size="lg"
        show={showItem}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {showProduct?.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h4>${showProduct?.price}</h4>
          <p>{showProduct?.description}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
      <Modal size="sm" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Success</Modal.Title>
        </Modal.Header>
        <Modal.Body>Your cart was updated!</Modal.Body>
        <Modal.Footer>
          <Button variant="success" onClick={() => setShow(false)}>
            Close
          </Button>
          <Button
            variant="success"
            onClick={() => {
              setShow(false);
              navigate("/OrderForm");
            }}
          >
            See Cart
          </Button>
        </Modal.Footer>
      </Modal>
      <Container>
        <center>
          <ShopTitle>{isHome ? "Shop" : "Browse"}</ShopTitle>
        </center>
        <Row>
          {isHome ? (
            products?.map((product) => (
              <Col
                xs={12}
                xl={3}
                md={6}
                className="mb-3"
                style={{ height: "100% !important" }}
              >
                <ItemPreview
                  setShowProduct={setShowProduct}
                  setShowItem={setShowItem}
                  product={product}
                  handleCart={addtoCart}
                  inCart={cart.hasOwnProperty(product.id)}
                  cartQuantity={
                    cart.hasOwnProperty(product.id) ? cart[product.id] : 1
                  }
                  openModal={setShow}
                />
              </Col>
            ))
          ) : (
            <Carousel breakPoints={breakPoints}>
              {products?.map((product, index) => (
                <Col style={{ height: "100% !important" }}>
                  <ItemPreview
                    setShowProduct={setShowProduct}
                    setShowItem={setShowItem}
                    product={product}
                    handleCart={addtoCart}
                    inCart={cart.hasOwnProperty(product.id)}
                    cartQuantity={
                      cart.hasOwnProperty(product.id) ? cart[product.id] : 1
                    }
                    openModal={setShow}
                  />
                </Col>
              ))}
            </Carousel>
          )}
        </Row>
        <Row>
          {/* <center>{!isHome ? <Link to="/Shop">See more!</Link> : null} </center> */}
        </Row>
      </Container>
    </div>
  );
};

const ShopTitle = styled.h1`
  font-size: 3rem;
  // margin-left: 3rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

const Container = styled.h1`
  margin: 0 10vw;
`;
export default Shop;
