import React, { useState } from "react";
import styled from "styled-components";
import { db } from "../../FirebaseConfig";
import { Col, Form } from "react-bootstrap";
import emailjs from "@emailjs/browser";
import { init } from "@emailjs/browser";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  setDoc,
  Timestamp,
} from "firebase/firestore";
import { useNavigate } from "react-router";

const CheckoutForm = ({ total, products }) => {
  let initialState = {
    name: "",
    email: "",
    repeatEmail: "",
    isValid: "",
    comment: "",
  };
  let navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(initialState);
  const handleName = (e) => {
    setUserInfo({ ...userInfo, name: e.target.value });
  };
  const handleEmail = (e) => {
    setUserInfo({ ...userInfo, email: e.target.value });
  };
  const handleRepeatEmail = (e) => {
    setUserInfo({ ...userInfo, repeatEmail: e.target.value });
  };
  const handleComment = (e) => {
    setUserInfo({ ...userInfo, comment: e.target.value });
  };
  const validateEmail = () => {
    if (userInfo.email === userInfo.repeatEmail) {
      // setUserInfo({...userInfo, isValid:"The emails match"})
      return true;
    } else {
      setUserInfo({
        ...userInfo,
        isValid: "Please make sure your email is correct",
      });
      return false;
    }
  };

  const updateQuantities = async (id, quantity) => {
    const itemRef = doc(db, "Products", id);
    const itemSnap = await getDoc(itemRef);
    if (itemSnap.exists()) {
      console.log(itemSnap.data().quantity >= quantity);
      if (itemSnap.data().quantity >= quantity) {
        await setDoc(
          itemRef,
          { quantity: itemSnap.data().quantity - quantity },
          { merge: true }
        );
        console.log("update quantity");
        return true;
      } else {
        alert(
          `Sorry! We only have ${itemSnap.data().quantity} of ${
            itemSnap.data().name
          } in stock!`
        );
        return false;
      }
    }
  };

  const sendConfirmationEmail = (order_ID) => {
    init("user_KVFsdBRdFnWmNU9Yhkqbv");
    let items = "<ul>";
    products.forEach((item) => {
      items += "<li>" + item.name + " x" + item.orderQuantity + "</li>";
    });
    items += "</ul>";
    const params = {
      to_email: userInfo.email,
      to_name: userInfo.name,
      order_total: total,
      date: new Date().toString(),
      comments: userInfo.comment,
      order_number: order_ID,
      items: items,
    };
    emailjs.send(
      "service_mnautoemail",
      "template_aqhkuym",
      params,
      "user_KVFsdBRdFnWmNU9Yhkqbv"
    );
  };

  const postTransaction = async () => {
    const productsList = {};
    let shouldPost = true;
    for (const prod of products) {
      productsList[prod.id] = prod.orderQuantity;
      const result = await updateQuantities(prod.id, prod.orderQuantity);
      if (!result) {
        return false;
      }
    }
    const newRef = await addDoc(collection(db, "Transactions"), {
      customer_name: userInfo.name,
      customer_email: userInfo.email,
      estimated_total: total,
      comments: userInfo.comment,
      timestamp: Timestamp.fromDate(new Date()),
      products: productsList,
    });
    return newRef.id;
  };

  const clearCart = () => {
    window.localStorage.setItem("cart", "");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail()) {
      const orderID = await postTransaction();
      if (orderID) {
        sendConfirmationEmail(orderID);
        setUserInfo(initialState);
        clearCart();
        navigate("/OrderInfo/" + orderID, { replace: true });
      }
    }
  };
  return (
    <div>
      <Col className="center mb-3">Place Your Order</Col>
      <Form onSubmit={handleSubmit}>
        <Col xs={12}>
          <Form.Label>Name:</Form.Label>
          <Form.Control
            type="text"
            onChange={handleName}
            id="name"
            placeholder="enter your name"
            value={userInfo.name}
            required
          ></Form.Control>
        </Col>
        <Col xs={12}>
          <Form.Label>Email:</Form.Label>
          <Form.Control
            type="email"
            onChange={handleEmail}
            id="email"
            placeholder="enter your email"
            value={userInfo.email}
            required
          ></Form.Control>
        </Col>
        <Col xs={12}>
          <Form.Label>Re-enter Email:</Form.Label>
          <Form.Control
            type="email"
            onChange={handleRepeatEmail}
            id="repeatEmail"
            placeholder="enter your email again"
            value={userInfo.repeatEmail}
            required
          ></Form.Control>
        </Col>
        <Col xs={12}>
          <Form.Label>Comments:</Form.Label>
          <Form.Control
            type="text"
            onChange={handleComment}
            id="comment"
            placeholder="Comments"
            value={userInfo.comment}
          ></Form.Control>
        </Col>
        <p>{userInfo.isValid}</p>
        <Col xs={12}>
          <Button type="submit">Place Order</Button>
        </Col>
        <Col>
          <Form.Text>
            By clicking this button, you give Midnight Nursery permission to
            send you emails regarding your order.
          </Form.Text>
        </Col>
      </Form>
    </div>
  );
};

// const Form = styled.form`
//   -webkit-appearance: none;
//   display: flex;
//   flex-direction: column;
//   align-content: flex-end;
//   jsutify-content: center;
//   align-items: flex-end;
// `;

const Quiet = styled.p`
  font-size: 0.6rem;
  text-align: center;
  width: 100%;
`;
const Label = styled.label`
  font-family: var(--font-family);
  padding: 10px 0;
  font-weight: bold;
  font-size: 0.9rem;
  display: inline-block;
  width: 30%;
  @media (min-width: 400px) {
    text-align: right;
  }
`;

const Input = styled.input`
  -webkit-appearance: none;
  margin-left: 10px;
  width: 190px;
  border: 0;
  height: 20px;
  padding: 10px;
  font-size: 0.9rem;
  border-radius: 5px;
`;
const Button = styled.button`
  height: 50px;
  width: 200px;
  margin: 10px;
  border-radius: 10px;
  font-size: 1.2rem;
  font-weight: bold;
  border: none;
  background-color: var(--green-color);
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  transition: 400ms ease;
  cursor: pointer;
  &:hover {
    background-color: var(--yellow-color);
    box-shadow: none;
  }
`;

export default CheckoutForm;
