import React from "react";
import { useNavigate } from "react-router";

//styling and icons
import styled from "styled-components";
import { BsFacebook, BsLinkedin, BsInstagram } from "react-icons/bs";
import { AiOutlineMail } from "react-icons/ai";
import Logo from "../../assets/Logo.PNG";

const Footer = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/");
  };
  return (
    <>
      <Wrapper>
        <Left>
          <Email
            href="mailto:midnightnurserykw@gmail.com"
            alt="email"
            target="_blank"
            title="Click here to send us an email"
          >
            {" "}
            <Span>Contact us</Span> <AiOutlineMail size={32} />
          </Email>
        </Left>
        <Center onClick={handleClick} title="Click here to go to Home page">
          <Image src={Logo} />
        </Center>
        <SocialContainer>
          <Facebook
            href="https://www.facebook.com/midnightnurserykw/"
            alt="link to facebook page"
            target="_blank"
            title="Click here to go to our FaceBook page"
          >
            <BsFacebook size={30} />
          </Facebook>

          <Instagram
            href="https://l.facebook.com/l.php?u=http%3A%2F%2Finstagram.com%2Fmidnightnursery%3Ffbclid%3DIwAR2juTXZouHZ6jhtSFSAP1lEB0djcHSGJ952wNNzJLAbU7zxBHFbFIfK2uM&h=AT3x_u5kJcaN5E5zF1Zbn2fFfI2Y9fFHwdZaWuY3cBmMv9AqKCK-kVHvQHTWtJBRurxEV6p-XXvCvWnZdt9fESqcswayZ3edCETUYHp0tJaIf2hNZnSa_965EJe4jHwm_o8FkQxk"
            alt="link to instagram"
            target="_blank"
            title="Click here to go to our Instagram page"
          >
            <BsInstagram size={30} />
          </Instagram>
          <Linkedin
            href="https://www.linkedin.com/company/midnight-nursery/"
            alt="link to linkedin"
            target="_blank"
            title="Click here to go to our LinkedIn page"
          >
            <BsLinkedin size={30} />
          </Linkedin>
        </SocialContainer>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  align-items: center;
  bottom : 0;
  left: 0;
  width: 100%;
  display: flex;
  margin-top:auto!important:
  padding: 10px 20px;
  justify-content: space-between;
  font-family: var(--font-family);
  font-weight: 500;
  height: 75px;
  font-size: 1.2rem;
  border-top: var(--green-color) 2px solid;
  background-color: linear-gradient(to bottom right, red,yellow));
  @media (max-width: 550px) {
  flex-direction: column; 
}

`;

const Left = styled.div`
  margin-left: 30px;
  @media (max-width: 550px) {
    margin-left: 10px;
  }
`;
const Image = styled.img`
  height: 50px;
  width: 50px;
  font-weight: 700;
  margin-top: 10px;
  @media (max-width: 550px) {
    display: none;
  }
`;
const Span = styled.span`
  font-size: 1.3rem;
  color: black;
  font:var(--font-family)
&: hover {
    color: green;
  }
  @media (max-width: 550px) {
    display: none;
  }
`;
const Center = styled.button`
  border: none;
  background: none;
`;
const SocialContainer = styled.div`
  display: flex;
  width: 100px;
  justify-content: space-evenly;
  cursor: pointer;
  margin-right: 50px;
  @media (max-width: 550px) {
    margin-right: 25px;
  }
`;

const Facebook = styled.a`
  /* color: #4968ad !important; */
  margin: 0 25px 0 0;
`;

const Email = styled.a`
  font-size: 1.5rem;
  color: black;
  /* margin: 0 50px ; */
  text-decoration: none;
  &: hover {
    color: green !important;
  }
`;

const Linkedin = styled.a`
  margin: 0 25px 0 0;
`;

const Instagram = styled.a`
  color: white;
  margin: 0 25px 0 0;
  background-clip: text;
  color: #dd2a7b;
  border-radius: 10px;
  &: hover {
    color: #8134af;
  }
`;

export default Footer;
