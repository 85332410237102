import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { db } from "../FirebaseConfig";
import { doc, getDoc } from "firebase/firestore";
import styled from "styled-components";
import OrderProducts from "./OrderProducts";

const OrderInfo = () => {
  const { orderId } = useParams();
  const [orderData, setOrderData] = useState([]);
  const [itemExists, setItemExists] = useState(true);
  useEffect(() => {
    const getData = async () => {
      const orderRef = doc(db, "Transactions", orderId);
      const orderSnap = await getDoc(orderRef);
      if (orderSnap.exists()) {
        let items = [];
        setOrderData({
          id: orderSnap.id,
          ...orderSnap.data(),
        });
      } else {
        setItemExists(false);
      }
    };
    getData();
  }, []);

  return itemExists ? (
    <Wrapper>
      <center>
        <H11>💚 Your order has been received! Thank you! 💚</H11>
        {/* <H11>Check your email for an order summary, and press ctrl+P to print this page</H11> */}
      </center>
      <H1>Order Confirmation</H1>
      <H5>
        <Bold>Order Number:</Bold> {orderId}
      </H5>
      <H5>
        <Bold>Name:</Bold> {orderData.customer_name}
      </H5>
      <H5>
        <Bold>Email:</Bold> {orderData.customer_email}
      </H5>
      <H51> Items purchased</H51>
      {orderData.products
        ? Object.keys(orderData.products)?.map((product) => (
            <OrderProducts
              item={{ id: product, quantity: orderData.products[product] }}
            />
          ))
        : null}
      <H52>Estimated total:$ {orderData.estimated_total}</H52>
    </Wrapper>
  ) : (
    <Wrapper>
      <H12>
        💔Sorry! It looks like this is not a valid order number. Please try
        again! Make sure there are no extra spaces.💔
      </H12>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  font: var(--font-family);
  margin: auto;
  width: 50%;
  height: fit-content;
  border: 1px solid grey;
  box-shadow: 3px 6px rgba(154, 205, 50, 0.2);
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items:center; */
  border-radius: 10px;
  margin-bottom: 50px;
  margin-top: 25px;
`;
const H11 = styled.h1`
  font-size: 1.5rem;
  text-align: center;
  margin: 5px 0;
`;

const Bold = styled.span`
  font-weight: bold;
`;

const H12 = styled.h1`
  font-size: 4rem;
  text-align: center;
  margin: 25px 20px;
`;
const H1 = styled.h1`
  text-decoration: underline;
  text-align: center;
  font-size: 2rem;
  margin: 5px 0;
`;

const H5 = styled.h5`
  margin: 5px 10px;
  font-size: 1.2rem;
  font-weight: 400;
  @media (max-width: 420px) {
    font-size: 0.8rem;
  }
`;
const H51 = styled.h5`
  margin: 5px 10px;
  font-size: 1.2rem;
  text-align: center;
  text-decoration: underline;
`;
const H52 = styled.h5`
  text-align: right;
  margin-right: 15px;
  font-size: 1.2rem;
`;
export default OrderInfo;
