import styled from "styled-components";

const Hamburger = ({ openBurger, toggleBurger }) => {
  return (
    <Hamburg onClick={toggleBurger}>
      <Burger></Burger>
      <Burger></Burger>
      <Burger></Burger>
    </Hamburg>
  );
};

const Hamburg = styled.div`
  position: relative;
  right: 1rem;
  height: 1.7rem;
  width: 1.7rem;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 20;
  display: none;
  &:hover {
    cursor: pointer;
  }

  @media (max-width: 917px) {
    display: flex;
    margin-left: 10px;
  }
  @media (max-width: 525px) {
    // margin-left: 50vw;
  }
  @media (max-width: 375px) {
    // margin-right: 50px;
  }
`;

const Burger = styled.div`
  width: 2rem;
  height: 0.25rem;
  border-radius: 10px;
  background: #616060;
  transform-origin: 1px;
  transition: all 0.3s linear;
  &:nth-child(1) {
    transform: ${({ openBurger }) =>
      openBurger ? "rotate(45deg)" : "rotate(0)"};
  }
  &:nth-child(2) {
    transform: ${({ openBurger }) =>
      openBurger ? "translateX(100%)" : "translateX(0)"};
    opacity: ${({ openBurger }) => (openBurger ? 0 : 1)};
  }
  &:nth-child(3) {
    transform: ${({ openBurger }) =>
      openBurger ? "rotate(-45deg)" : "rotate(0)"};
  }
`;
export default Hamburger;
