const Weather = () => {
  return (
    <div style={{ paddingTop: "5vh" }}>
      <a
        className="weatherwidget-io"
        href="https://forecast7.com/en/43d46n80d52/waterloo/"
        data-label_1="WATERLOO"
        data-label_2="WEATHER"
        data-theme="sky"
      >
        WATERLOO WEATHER
      </a>
      {
        !(function (d, s, id) {
          var js,
            fjs = d.getElementsByTagName(s)[0];
          if (!d.getElementById(id)) {
            js = d.createElement(s);
            js.id = id;
            js.src = "https://weatherwidget.io/js/widget.min.js";
            fjs.parentNode.insertBefore(js, fjs);
          }
        })(document, "script", "weatherwidget-io-js")
      }
    </div>
  );
};

export default Weather;
