import React, { useState } from "react";
import { CardGroup, Card, Button } from "react-bootstrap";
import styled from "styled-components";

const ItemPreview = ({
  product,
  handleCart,
  inCart,
  cartQuantity,
  openModal,
  setShowProduct,
  setShowItem,
}) => {
  const [quantity, SetQuantity] = useState(
    product.quantity > 0 ? cartQuantity : 0
  );
  const [outOfStock, setOutOfStock] = useState(product.quantity <= 0);
  const [amountInCart, setAmountInCart] = useState(cartQuantity);
  const increment = (totalQuantity) => {
    const quant = quantity;
    if (quant < totalQuantity) SetQuantity(quant + 1);
  };
  const decrement = () => {
    const quant = quantity;
    if (quant > 0) {
      SetQuantity(quant - 1);
    }
  };
  return (
    <Card className="h-100" style={{ height: "100% !important" }}>
      <CardImg variant="top" src={product.image} />
      <Card.Body>
        <Card.Title>{product.name} </Card.Title>
        <Card.Title>${product.price} </Card.Title>
        <Card.Text>
          {product.description.slice(0, 100)}{" "}
          {product.description.length > 100 ? (
            <Link
              onClick={() => {
                setShowProduct(product);
                setShowItem(true);
              }}
            >
              ...See More
            </Link>
          ) : null}
        </Card.Text>
        <CardButtons>
          <QuantityButtons>
            <IncrementButton onClick={decrement}>-</IncrementButton>
            <Quantity>{quantity}</Quantity>
            <IncrementButton onClick={() => increment(product.quantity)}>
              +
            </IncrementButton>
          </QuantityButtons>

          <CardButton
            variant="success"
            disabled={outOfStock}
            onClick={() => {
              handleCart(product.id, quantity);
              openModal(true);
              setAmountInCart(quantity);
            }}
          >
            {inCart ? "Update Cart" : "Add to Cart"}
          </CardButton>
        </CardButtons>
      </Card.Body>
      {inCart ? (
        <Card.Footer>
          <center> {amountInCart} in Cart </center>
        </Card.Footer>
      ) : (
        <Card.Footer style={outOfStock ? null : { visibility: "hidden" }}>
          Out of Stock!
        </Card.Footer>
      )}
    </Card>
  );
};

const Link = styled.span`
  text-decoration: underline;
  &:hover {
    cursor: pointer;
  }
`;

const IncrementButton = styled.button`
  width: 30%;
  border: 1px solid rgb(34, 139, 34, 1);
  background-color: white;
  color: rgb(34, 139, 34, 1);
  margin: 0 2px;
  border-radius: 5px;
  font-size: 1rem;
`;
const QuantityButtons = styled.div`
  display: flex;
  justify-content: space-around;
  width: 40%;
`;
const CardButton = styled.button`
  width: 50%;
  height: auto;
  border-radius: 5px;
  font-weight: bold;
  background-color: white;
  border: 1px solid green;
  color: green;
  font-size: 0.7rem;
  padding: 5px;
  &:hover {
    background-color: rgb(34, 139, 34, 1);
    border: 1px solid black;
    color: white;
  }
  &:disabled {
    cursor: not-allowed;
  }
`;

const CardImg = styled.img`
  width: 100%;
  height: 200px;
  padding: 10px;
  object-fit: cover;
  border-radius: 10px;
`;
const Quantity = styled.p`
  width: 50%;
  text-align: center;
  //   background-color: blue;
  // box-shadow: 0px 0px 2px 2px #d3d3d3;
  border: 1px solid rgb(34, 139, 34, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 5px;
`;
const CardButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  height: auto;
`;
export default ItemPreview;
