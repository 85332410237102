export const growingRes = [
{
    id: 100001,
    imgSrc:"src/Components/sapling.jpg",
name:"Bean",
type:"contender, bush",
watering:"Water 1X weekly, soak through.",
soilType:"Plant in fluffy soil with good drianage. Drainage can be improved by adding compost and other organic matter.",
sunlightReq:"Full sun, shelter after transplanting.",
fruitDesc:"Produces abundant quantities of stringless pods, pick when 5-6 inches for best taste.",
additionalDetails:[
    "Ideal bean perfect for cooler climates.",
    "Requires a trellis/pole for climbing."
]
},
{
    id:100002,
    imgSrc:"src/Components/saplings.jpg",
    name:"Cucumber",
    type:"Patio Snacker",
    watering:"Water deeply once a week.",
    soilType:"Keep warm and well-drained.",
    sunlightReq:"Full sun exposure(6+ hours if sunlight).",
    fruitDesc:"18 cm long, dark green and shiny",
    additionalDetails:[
        "Grow vertically on a trellis, fence or other support."
    ]
}

]
